
<template>
  <div class="d-flex flex-row align-center">
    <slot name="status">
      <v-chip
        v-if="status.isBeyondTarget || status.isClosed"
        :color="colorStatus"
        :outlined="outlinedChip"
        :small="smallChip"
        :large="largeChip"
        class="mr-3"
      >
        <span class="mr-1">{{status.text}}</span>
      </v-chip>
      <div v-else>
        <span class="mr-1">{{status.text}}</span>
      </div>
    </slot>
    <span class="ml-1 mr-1">{{daysSummary}}</span>
    <calendar-date
      v-if="status.isClosed"
      :date="status.statusChangedAt"
      :lowerCase="true"
    />
  </div>
</template>

<script>
export default {
  components: {
    CalendarDate: () => import('@/components/calendar-date')
  },
  computed: {
    daysSummary () {
      if (!this.status.isClosed) {
        const daysBeforeTarget = this.moment(this.status.targetDate).diff(this.moment().startOf('day'), 'days')
        return daysBeforeTarget > 0
          ? daysBeforeTarget + ' ' + this.$t('t.DaysBeforeDate')
          : daysBeforeTarget < 0
            ? -daysBeforeTarget + ' ' + this.$t('t.DaysPastDue')
            : this.$t('t.DeadlineToday')
      } else {
        return this.calendarLinkText
      }
    },
    calendarLinkText () {
      const r = null

      const dateForDiff = this.moment(this.status.statusChangedAt)
      const days = Math.abs(this.moment().startOf('day').diff(dateForDiff.startOf('day'), 'days'))

      if (this.status.isClosed && days > 1) {
        return `${this.$t('t.At').toLowerCase()}`
      }

      return r
    },
    colorStatus () {
      if (!this.status.isClosed) {
        return this.status.isBeyondTarget ? 'error' : 'primary'
      }

      return 'success'
    }
  },
  props: {
    status: Object,
    smallChip: Boolean,
    largeChip: Boolean,
    outlinedChip: Boolean
  }
}
</script>

<style lang="stylus" scoped></style>
